{
  "name": "poshvine-visa-contactless",
  "version": "2.1.6",
  "scripts": {
    "ng": "ng",
    "watch": "ng build --watch --configuration sandbox",
    "test": "ng test",
    "build": "ng build --configuration sandbox",
    "lint": "ng lint",
    "lint:fix": "npm run lint --fix",
    "serve:activation": "ng serve --configuration=activation-sandbox --disable-host-check --port=9201",
    "build:activation-prod": "ng build --configuration=activation-prod --output-hashing=all",
    "build:activation-sandbox": "ng build --configuration=activation-sandbox --output-hashing=all && && node modify-index.js visa-activation",
    "serve:contactless": "ng serve --configuration=contactless-sandbox --disable-host-check --port=9200",
    "build:contactless-prod": "ng build --configuration=contactless-prod --output-hashing=all",
    "build:contactless-sandbox": "ng build --configuration=contactless-sandbox --output-hashing=all && node modify-index.js visa-contactless",
    "sourcemap:inject": "sentry-cli sourcemaps inject dist",
    "sourcemap:upload-prod": "sentry-cli sourcemaps upload --dist=production --release=$npm_package_version --org=rzp --project=poshvine-visa-contactless  dist",
    "sourcemap:upload-sandbox": "sentry-cli sourcemaps upload --dist=sandbox --release=$npm_package_version --org=rzp --project=poshvine-visa-contactless  dist",
    "build:upload-sourcemaps-prod": "npm run build:prod && npm run sourcemap:inject && npm run sourcemap:upload-prod",
    "build:upload-sourcemaps-sandbox": "npm run build:sandbox && npm run sourcemap:inject && npm run sourcemap:upload-sandbox"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.3.0",
    "@angular/common": "^17.3.0",
    "@angular/compiler": "^17.3.0",
    "@angular/core": "^17.3.0",
    "@angular/forms": "^17.3.0",
    "@angular/platform-browser": "^17.3.0",
    "@angular/platform-browser-dynamic": "^17.3.0",
    "@angular/router": "^17.3.0",
    "@pv-frontend/authentication": "^1.1.3",
    "@pv-frontend/header": "^1.0.2",
    "@pv-frontend/pv-shared-components": "^5.3.2",
    "@pv-frontend/pv-shared-directives": "^1.7.0",
    "@pv-frontend/pv-shared-services": "^2.3.0",
    "@sentry/angular": "^8.7.0",
    "@sentry/cli": "^2.32.1",
    "@types/crypto-js": "^3.1.47",
    "crypto-js": "^4.0.0",
    "jsdom": "^25.0.1",
    "primeflex": "^3.3.1",
    "primeng": "^15.4.1",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.2",
    "@angular-eslint/builder": "16.2.0",
    "@angular-eslint/eslint-plugin": "16.2.0",
    "@angular-eslint/eslint-plugin-template": "16.2.0",
    "@angular-eslint/schematics": "16.2.0",
    "@angular-eslint/template-parser": "16.2.0",
    "@angular/cli": "^17.3.2",
    "@angular/compiler-cli": "^17.3.0",
    "@changesets/cli": "^2.27.8",
    "@types/jasmine": "~5.1.0",
    "@typescript-eslint/eslint-plugin": "5.62.0",
    "@typescript-eslint/parser": "5.62.0",
    "changeset": "^0.2.6",
    "eslint": "^8.49.0",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.4.2"
  }
}
